import {
  StoryblokComponent,
  storyblokEditable,
  useStoryblokState,
} from "gatsby-source-storyblok"

import Layout from "../../components/structural/layout"
import PropTypes from "prop-types"
import React from "react"
import Seo from "../../components/seo"
import { graphql } from "gatsby"

const Projects = props => {
  const { data, pageContext } = props
  const story = useStoryblokState(
    data?.storyblokEntry ? data.storyblokEntry : pageContext.story
  )

  const { content } = story
  const components = content?.content?.map(blok => (
    <StoryblokComponent blok={blok} doc={blok} key={blok._uid} />
  ))

  const firstComponent =
    content?.content?.length > 0 ? content?.content[0]?.component : "unknown"

  return (
    <Layout>
      <div
        {...storyblokEditable(content)}
        className={`mx-auto ${firstComponent !== "section_hero" && `mt-20`}`}
      >
        {components}
      </div>
    </Layout>
  )
}

export default Projects

Projects.defaultProps = {
  data: null,
  pageContext: null,
}

Projects.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
}

export const Head = ({ data, pageContext }) => {
  const story = useStoryblokState(
    data?.storyblokEntry ? data.storyblokEntry : pageContext.story
  )

  const { seo, seo_author, seo_image } = story.content

  const siteSettings = JSON.parse(data?.settings?.content)

  return (
    <Seo
      settings={siteSettings}
      {...seo}
      author={seo_author}
      image={seo_image}
    />
  )
}

export const QUERY = graphql`
  query ProjectsIndexQuery {
    settings: storyblokEntry(full_slug: { eq: "site-settings" }) {
      content
      name
      full_slug
      uuid
      id
      internalId
    }
  }
`
